import React from 'react';
import './DetalhesDivida.css';

import CardParcela from '../card-parcela/card-parcela';

const DetalhesDivida = props => {
	return (
		<div className='container-detalhes-modal'>
			<div className='wrap-detalhes-modal'>
				<div>
					{Object.keys(props.detalhes).map(d => {
						if ('parcelas' !== d) {
							return (
								<p key={d}>
									<b>{d}</b>
									{`: ${props.detalhes[d]}`}
								</p>
							);
						}
						return null;
					})}
				</div>
				<div className="wrap-detalhes-modal-cards">
					<CardParcela detalhes={props.detalhes}/>
				</div>
				<div style={{ margin: 'auto auto 0px auto', display: 'flex' }}>
					<button
						style={{ height: '30px', margin: '40px auto 15px auto', width: '100px', textAlign: 'center', background: '#051773' }}
						className='Button'
						onClick={props.onClose}>
						Ok
					</button>
				</div>
			</div>
		</div>
	);
};

export default DetalhesDivida;
